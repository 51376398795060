import axios from 'axios';


const DOMAIN = process.env.REACT_APP_DOMAIN
const BASE_URL = process.env.REACT_APP_BASE_URL
const BASE_APP_AUTH = process.env.REACT_APP_AUTH
const REGISTER = 'register'   
const EMAIL_CONFIRMATION = 'confirm-email' 



export const signup = async (postData) =>
    await axios.post(`${DOMAIN}/${BASE_URL}/${REGISTER}`, postData);


export const confirmEmailRegistration = async (postData) =>
    await axios.post(`${DOMAIN}/${BASE_APP_AUTH}/${EMAIL_CONFIRMATION}`, postData);



