import {
    COLOR_LINK
} from './../../theme/colors';

export default theme => ({
    root: {},
    boxBack: {
        cursor: 'pointer',
        margin: '0px 0 10px',
        display: 'inline-flex',
        alignItems: 'center',
        '& p': {
            fontSize: '16px',
            lineHeight: '39px'
        }
    },
    boxInputs: {
        maxWidth: '450px'
    },
    gridActionsButton: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        '& button': {
            width: 'max-content'
        }
    },
    boxTerms: {
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: '36px',
        '& p': {
            fontSize: '12px',
            lineHeight: '24px',
            marginLeft: '10px',
            '& span': {
                color: COLOR_LINK,
                marginRight: '2px',
                cursor: 'pointer',
                fontWeight: 'bold'
            }
        },
    },
    fullWidth: {
        width: '100%'
    }
})