const ICONS = {
    mnu_logo: require('./logo-mnu.svg'),
    cyc_logo: require('./logo-cycloid.svg'),
    facebook: require('./facebook.svg'),
    close: require('./close.svg'),
    menu: require('./menu.svg'),
    phone_red_shadow: require('./phone-red-shadow.svg'),
    section_background: require('./section-background.svg'),
    func_1: require('./Icon-func-1.svg'),
    func_2: require('./Icon-func-2.svg'),
    func_3: require('./Icon-func-3.svg'),
    func_4: require('./Icon-func-4.svg'),
    cm_func_1: require('./cm-func-1.svg'),
    cm_func_2: require('./cm-func-2.svg'),
    cm_func_3: require('./cm-func-3.svg'),
    red_arrow_hrz: require('./red-arrow-hrz.svg'),
    red_arrow_vrt: require('./red-arrow-vrt.svg'),
    qr_red_shadow: require('./shadow-qr.svg'),
    qrcode: require('./qrcode.png'),
    free_badge: require('./free-badge.svg'),
    pt_flag: require('./pt-flag.svg'),
    en_flag: require('./en-flag.svg'),
    es_flag: require('./es-flag.svg'),
    fr_flag: require('./fr-flag.svg'),
    drop_arrow: require('./drop-down-arrow.svg')
}


export default ICONS;