import { createMuiTheme } from '@material-ui/core';
import { 
  COLOR_INPUT_LABEL,
  COLOR_INPUT_LINE,
  COLOR_PRIMARY,
  COLOR_PRIMNARY_HOVER,
  BUTTOM_DISABLED,
  BUTTOM_DISABLED_TEXT
} from './colors';


const theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        color: COLOR_INPUT_LABEL,
        '&.Mui-focused': {
          color: COLOR_INPUT_LABEL,
        },
        '&.Mui-error': {
          color: COLOR_INPUT_LABEL,
        }
      }
    },
    MuiInputBase: {
      input: {
        color: 'white'
      }
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          borderBottomColor: COLOR_INPUT_LINE,
        },
        '&:before': {
          borderBottomColor: COLOR_INPUT_LINE
        },
        '&:after': {
          borderBottomColor: COLOR_INPUT_LINE
        },
        '&.Mui-error':{
          '&:after': {
            borderBottomColor: COLOR_PRIMARY
          }
        }
      }
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          color: COLOR_PRIMARY
        }
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Open Sans',
        backgroundColor: COLOR_PRIMARY,
        color: '#FFFFFF',
        '&:hover': {
          backgroundColor: COLOR_PRIMNARY_HOVER
        },
        '&.Mui-disabled': {
          backgroundColor: BUTTOM_DISABLED,
          color: BUTTOM_DISABLED_TEXT,
        }
      },
      text: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '10px',
        paddingBottom: '10px',
      }
    },
    MuiCheckbox: {
      root: {
        color: `${COLOR_PRIMARY} !important`,
        padding: 0
      }
    },
      MuiPaper: {
      rounded: {
        borderRadius: '10px'
      }
    },
    MuiTab: {
      textColorPrimary: {
        '&.Mui-selected': {
          color: '#8C161E',
          fontWeight: 'bold'
        }
      },
    },
    MuiDialogContent: {
      root: {
        padding: '30px !important',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          width: '0px',
          background: 'transparent'
        }
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '10px'
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        '& .Mui-expanded': {
          '& .MuiTypography-root': {
            fontWeight: 'bold !important'
          }
        }
      }
    },
    MuiTypography: {
      h1: {
        fontFamily: 'Open Sans'
      }
    } 
  }
});

theme.typography.h1 = {
  fontSize: '3.5rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '2.125rem',
  },
};
export default theme