import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import styles from './styles';
import restaurante_la_cantina from '../../assets/imgs/la_cantina.png';
import pizza_speciale from '../../assets/imgs/pizza_especiale.png'
import restaurante_la_cantina_2x from '../../assets/imgs/la_cantina@2x.png';
import pizza_speciale_2x from '../../assets/imgs/pizza_especiale@2x.png'

import ICONS from '../../assets/svgs/icons.js';

import { withStyles, Button, Container, Grid, Hidden, Paper, Dialog, DialogContent, Link, Popper, Grow, ClickAwayListener, MenuList, MenuItem, ListItemIcon } from '@material-ui/core';

import FormStep1 from '../../components/FormStep1';
import FormStep2 from '../../components/FormStep2';
import Popup from '../../components/Popup';
import Faqs from '../../components/Faqs';
import Loader from '../../components/Loader';


import * as apiService from '../../services/api';

import TermsAndConditions from '../../components/TermsAndConditions';
import Policy from '../../components/Policy';

import './style.css';

const VERSION = process.env.REACT_APP_TEST;

const isRetina = false //window.devicePixelRatio > 1 ;

const languages = [
    { id: 'pt', label: 'Português', icon: ICONS.pt_flag },
    { id: 'en', label: 'Inglês', icon: ICONS.en_flag },
    { id: 'es', label: 'Espanhol', icon: ICONS.es_flag },
    { id: 'fr', label: 'Francês', icon: ICONS.fr_flag },
]

const Register = ({ classes }) => {

    const history = useHistory();

    const urlParams = new URLSearchParams(useLocation().search);
    const token = urlParams.get('token');
    const lang = urlParams.get('lang');

    const [step, setStep] = useState(1)
    const [status, setStatus] = useState('LOADING');
    const [openDialog, setOpenDialog] = useState(false);
    const [openFaq, setOpenFaq] = useState(false);
    const [validatingEmail, setValidatingEmail] = useState(false)

    const [openTerms, setOpenTerms] = useState(false)
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)

    const [data, setData] = useState({
        establishmentName: '',
        email: '',
        phone: '',
        address: '',
        zipCode: '',
        city: ''
    })

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [open, setOpen] = React.useState(false);
    const { t, i18n } = useTranslation();
    const anchorRef = React.useRef(null);
    const currentLanguage = languages.find(language => language.id === i18n.language);

    useEffect(() => {
        if (token) {
            confirmRegistration(token);
        }
        if (lang) {
            localStorage.setItem('lang', lang);
            i18next.changeLanguage(lang);
        }
    }, [])


    const confirmRegistration = async (token) => {
        setValidatingEmail(true)
        const dataPost = {
            token: token
        }
        try {
            await apiService.confirmEmailRegistration(dataPost)
            setValidatingEmail(false)
            setStatus('EMAIL_CONFIRM_SUCCCESS')
            setOpenDialog(true);
        } catch (error) {
            setValidatingEmail(false)
            setStatus('EMAIL_CONFIRM_ERROR')
            setOpenDialog(true);
        }
    }

    const nextStep = (data) => {
        setData(previous => ({
            ...previous,
            ...data
        })
        )
        setStep(2);
    }


    const signup = async (dt) => {
        setOpenDialog(true);
        setStatus('LOADING');
        const dataPost = {
            lang: localStorage.getItem("lang"),
            ...data,
            ...dt
        }
        try {
            await apiService.signup(dataPost);
            setStatus('SUCCCESS');
            setData({
                establishmentName: '',
                email: '',
                phone: '',
                address: '',
                zipCode: '',
                city: ''
            })
        } catch (error) {
            console.log(error)
            const statusCode = error.response.status
            switch (statusCode) {
                case 409:
                    setStatus('EMAIL_ALREADY_EXIST')
                    break;

                default:
                    setStatus('ERROR');
                    break;
            }
        }
    }


    const closeHandler = () => {
        const lastStatus = status;
        setOpenDialog(false);
        setStatus('LOADING');
        if (lastStatus === 'SUCCCESS') {
            setStep(1);
        }
        if (lastStatus === 'EMAIL_CONFIRM_SUCCCESS' || lastStatus === 'EMAIL_CONFIRM_ERROR') {
            history.push('/')
        }
    }

    const back = (data) => {
        setData(previous => ({
            ...previous,
            ...data
        }))
        setStep(1)
    }

    const login = () => {
        window.open("https://cy-cms.pt/auth/login", "_blank")
        setData({
            establishmentName: '',
            email: '',
            phone: '',
            address: '',
            zipCode: '',
            city: ''
        })
        setOpenDialog(false);
        setStep(1)
    }

    const onClickOpenFaq = () => {
        setOpenFaq(true)
    }

    const onClickOpenTerms = () => {
        setOpenTerms(true)
    }

    const onClickOpenPrivacyPolicy = () => {
        setOpenPrivacyPolicy(true)
    }

    const faqsRender = openFaq ? <Faqs closeFaqs={() => setOpenFaq(false)} /> : null
    const loader = validatingEmail ? <Loader /> : null

    const termsAndCond = openTerms ? <TermsAndConditions closeTerms={() => setOpenTerms(false)} /> : null
    const privacyPolicy = openPrivacyPolicy ? <Policy closePolicies={() => setOpenPrivacyPolicy(false)} /> : null

    const smoothScroolEvent = (section, yAdjust) => {
        let yValue = yAdjust;
        if (!Boolean(yAdjust))
            yValue = 80;
        var sectionElement = document.getElementById(section).offsetTop;
        window.scrollTo({ top: sectionElement - yValue, left: 0, behavior: 'smooth' });
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleClickLanguage = (language) => {
        const lng = language.id;
        localStorage.setItem('lang', lng);
        i18next.changeLanguage(lng);
        setOpen(false);
    }

    return (
        <>
            <Container maxWidth="lg" justify="center" className={classes.root}>

                <div className="main-conatiner-view">

                    {/* MENU HEADER  */}
                    <Grid item className={classes.header}>
                        <div className={classes.headerContent}>
                            <div className={classes.boxLogo} onClick={() => { smoothScroolEvent('registo'); setIsMenuOpen(false) }}>
                                <img src={ICONS.mnu_logo} />
                            </div>
                            <div className={classes.optionsHeader}>
                                <li><a onClick={() => smoothScroolEvent('funcionalidades', -100)} title={t('hd-opt-features')}>{t('hd-opt-features')}</a></li>
                                <li><a onClick={() => smoothScroolEvent('como-funciona')} title={t('hd-opt-works')}>{t('hd-opt-works')}</a></li>
                                <li><a href="mailto:info@mnu.pt" title={t('hd-opt-contact')}>{t('hd-opt-contact')}</a></li>
                                {/* <div className="header-option-buttons"><p  >{t('hd-opt-features')}</p></div>
                                <div className="header-option-buttons"><p  >{t('hd-opt-works')}</p></div>
                                <div className="header-option-buttons"><a  href="mailto:info@mnu.pt">{t('hd-opt-contact')}</a></div> */}
                                {/* <p onClick={() => onClickOpenFaq()}>{t('hd-opt-faqs')}</p> */}
                            </div>
                            <div className={classes.actionsHeader}>
                                <Button type="submit" className="button" onClick={() => { window.open('https://cy-cms.pt') }}>{t('hd-opt-login')}</Button>
                                <div style={{ borderRight: '1px solid #707070', height: '20px' }}></div>
                            </div>
                            <div className={classes.actionsHeaderLng}>
                                <div className="language-container">
                                    <Button
                                        className="button-toogle-menu"
                                        ref={anchorRef}
                                        aria-controls={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        startIcon={<img alt="flag" style={{ width: '25px' }} src={currentLanguage.icon} />}
                                        endIcon={<img alt="flag" style={{ width: '25px', margin: '-10px', transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s linear' }} src={ICONS.drop_arrow} />}
                                        onClick={handleToggle}
                                    ></Button>

                                    <Popper
                                        className="menu"
                                        placement="bottom-end"
                                        open={open}
                                        anchorEl={anchorRef.current}
                                        role={undefined}
                                        transition
                                        disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper elevation={3} className="paper">
                                                    <ClickAwayListener onClickAway={handleClose}>
                                                        <MenuList autoFocusItem={open} id="menu-list-grow" className="menu-list">
                                                            {languages.map((language) => (
                                                                <MenuItem
                                                                    key={language.id}
                                                                    className="menu-item"
                                                                    onClick={() => handleClickLanguage(language)}>
                                                                    <ListItemIcon>
                                                                        {<img alt="flag" style={{ width: '25px' }} src={language.icon} />}
                                                                    </ListItemIcon>
                                                                    {t(language.id)}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            </div>
                            <div className={classes.actionsMobileHeader} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                                <img src={isMenuOpen ? ICONS.close : ICONS.menu} />
                            </div>
                        </div>
                    </Grid>

                    {/* MENU LIST MOBILE */}
                    <div className="header-menu-container" style={isMenuOpen ? { top: '70px' } : { top: '-400px' }}>
                        <div className="header-menu-row"><p onClick={() => { smoothScroolEvent('funcionalidades', 23); setIsMenuOpen(false) }}>{t('hd-opt-features')}</p></div>
                        <div className="header-menu-row"><p onClick={() => { smoothScroolEvent('como-funciona'); setIsMenuOpen(false) }}>{t('hd-opt-works')}</p></div>
                        <div className="header-menu-row"><a href="mailto:info@mnu.pt">{t('hd-opt-contact')}</a></div>
                        {/* <div className="header-menu-row"><p onClick={() => { onClickOpenFaq(); setIsMenuOpen(false) }}>{t('hd-opt-faqs')}</p></div> */}
                        <div className="header-menu-row"><Button type="submit" onClick={() => { smoothScroolEvent('registo'); setIsMenuOpen(false) }} >{t('hd-opt-register')}</Button></div>
                        <div className="header-menu-row"><Button type="submit" className="button" onClick={() => { window.open('https://cy-cms.pt') }}>{t('hd-opt-login')}</Button></div>
                    </div>
                    <div className="header-menu-layer" style={isMenuOpen ? { opacity: '0.8' } : { opacity: '0', pointerEvents: 'none' }} onClick={() => { if (isMenuOpen) setIsMenuOpen(false); }}></div>


                    {/* SECTION 1 - REGISTO */}
                    <section id="registo" className="section-container" style={{ minHeight: '730px', height:'100vh', zIndex: '0' }}>
                        <div className="registo-container-area" style={{ maxWidth: '1280px'}}>
                            <div className="registo-form-area">
                                {
                                    step === 1 ?
                                        <FormStep1 data={data} nextStep={nextStep} />
                                        :
                                        <FormStep2
                                            data={data}
                                            back={(data) => { back(data) }}
                                            createAccount={(data) => signup(data)}
                                        />
                                }
                                {/* <img className="image-area-item" src={ICONS.free_badge} /> */}
                                <div className="image-area-item" style={{ width: '80px', height: '80px', justifyContent: 'center', alignItems: 'center', marginRight: '20px' }}>
                                    <img src={ICONS.free_badge} />
                                    <div style={{ color: '#ffffff', fontSize: '15px', fontFamily: "'Open Sans', sans-serif", fontWeight: '800', position: 'absolute', zIndex: '6', transform: 'rotateZ(-20deg)' }}>{t('s0-badge-free')}</div>
                                </div>
                            </div>
                            <div className="registo-image-area" style={{paddingTop:'30px'}}>
                                <img className="image-area-item" src={!isRetina ? restaurante_la_cantina : restaurante_la_cantina_2x} />
                                <img className="image-area-item" src={!isRetina ? pizza_speciale : pizza_speciale_2x} />
                                <img className="image-area-item" src={ICONS.phone_red_shadow} />
                                <div className="image-area-item" style={{ width: '80px', height: '80px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={ICONS.free_badge} />
                                    <div style={{ color: '#ffffff', fontSize: '15px', fontFamily: "'Open Sans', sans-serif", fontWeight: '800', position: 'relative', zIndex: '6', transform: 'rotateZ(-20deg)' }}>{t('s0-badge-free')}</div>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* SECTION 2 - FUNCIONALIDADES */}
                    <section id="funcionalidades" className="section-container" style={{ zIndex: '1', top: '-58px' }}>
                        <img className="section-background-layer" style={{ width: '100%', position: 'relative', top: '1px' }} src={ICONS.section_background} />
                        <div className="section-area" style={{ backgroundColor: '#171717' }}>
                            <div className="section-title">{t('s1-title')}</div>
                            <div className="section-subtitle">{t('s1-subtitle')}</div>
                            <div className="section-area-wrap">
                                <div className="section-card">
                                    <div className="section-card-icon"><img src={ICONS.func_1} /></div>
                                    <div className="section-card-title">{t('s1-card1-title')}</div>
                                    <div className="section-card-subtitle">{t('s1-card1-subtitle')}</div>
                                </div>
                                <div className="section-card">
                                    <div className="section-card-icon"><img src={ICONS.func_2} /></div>
                                    <div className="section-card-title">{t('s1-card2-title')}</div>
                                    <div className="section-card-subtitle">{t('s1-card2-subtitle')}</div>
                                </div>
                            </div>
                            <div className="section-area-wrap">
                                <div className="section-card">
                                    <div className="section-card-icon"><img src={ICONS.func_3} /></div>
                                    <div className="section-card-title">{t('s1-card3-title')}</div>
                                    <div className="section-card-subtitle">{t('s1-card3-subtitle')}</div>
                                </div>
                                <div className="section-card">
                                    <div className="section-card-icon"><img src={ICONS.func_4} /></div>
                                    <div className="section-card-title">{t('s1-card4-title')}</div>
                                    <div className="section-card-subtitle">{t('s1-card4-subtitle')}</div>
                                </div>
                            </div>
                            <div className="section-actions">
                                <Button type="submit" style={{ margin: '10px' }} onClick={() => { smoothScroolEvent('registo'); setIsMenuOpen(false) }} >{t('s1-action-btn1')}</Button>
                            </div>
                        </div>
                    </section>


                    {/* SECTION 3 - COMO FUNCIONA */}
                    <section id="como-funciona" className="section-container" style={{ zIndex: '0', marginBottom: '120px' }}>
                        <div className="section-area">
                            <div className="section-title">{t('s2-title')}</div>
                            <div className="section-subtitle">{t('s2-subtitle')}</div>
                            <div className="section-area-wrap">
                                <div className="cf-card">
                                    <div className="cf-card-title">{t('s2-card1-title')}</div>
                                    <div className="cf-card-icon"><img src={ICONS.cm_func_1} /></div>
                                    <div className="cf-card-subtitle">{t('s2-card1-subtitle')}</div>
                                    <div className="cf-card-description">{t('s2-card1-description')}</div>
                                </div>
                                <div className="cf-arrow-container">
                                    <img style={{ transform: 'rotateY(180deg)', left: '-20px' }} src={ICONS.red_arrow_vrt} />
                                    <img src={ICONS.red_arrow_hrz} />
                                </div>
                                <div className="cf-card">
                                    <div className="cf-card-title">{t('s2-card2-title')}</div>
                                    <div className="cf-card-icon"><img src={ICONS.cm_func_2} /></div>
                                    <div className="cf-card-subtitle">{t('s2-card2-subtitle')}</div>
                                    <div className="cf-card-description">{t('s2-card2-description')}</div>
                                </div>
                                <div className="cf-arrow-container">
                                    <img style={{ right: '-20px' }} src={ICONS.red_arrow_vrt} />
                                    <img src={ICONS.red_arrow_hrz} />
                                </div>
                                <div className="cf-card">
                                    <div className="cf-card-title">{t('s2-card3-title')}</div>
                                    <div className="cf-card-icon"><img src={ICONS.cm_func_3} /></div>
                                    <div className="cf-card-subtitle">{t('s2-card3-subtitle')}</div>
                                    <div className="cf-card-description">{t('s2-card3-description')}</div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* SECTION 4 - PARA CLIENTES */}
                    <section id="para-clientes" className="section-container" style={{ zIndex: '0', marginBottom: '50px' }}>
                        <div className="section-area">
                            <div className="section-title">{t('s3-title')}</div>
                            <div className="section-subtitle">{t('s3-subtitle')}</div>
                            <div className="section-area-wrap">
                                <div className="pc-card">
                                    <div className="pc-card-title">{t('s3-card1-title')}</div>
                                    <div className="pc-arrow-icon">
                                        <img src={ICONS.red_arrow_vrt} />
                                    </div>
                                    <div className="pc-card-qrcode">
                                        <img src={ICONS.qr_red_shadow} />
                                        <img src={ICONS.qrcode} />
                                    </div>
                                </div>
                            </div>
                            <div className="section-actions" style={{marginTop: '0px'}}>
                                <Button type="submit" style={{ margin: '10px' }} onClick={() => { smoothScroolEvent('registo'); setIsMenuOpen(false) }} >{t('s3-action-btn1')}</Button>
                                <Button component="a" type="submit" style={{ margin: '10px' }} className="button" href="./docs/mnu-qrcode-file.pdf" download="menu-qrcode.pdf">{t('s3-action-btn2')}</Button>
                            </div>
                        </div>
                    </section>

                </div>

                <div className={classes.footer}>
                    <div className={classes.footerContent}>
                        <div className={classes.assigneCycBox}>
                            Powered by
                            <Link underline="none" color="inherit" href={"https://www.cycloid.pt/"} target="_blank">
                                <img src={ICONS.cyc_logo}></img>
                            </Link>
                            {` | v${VERSION}`}
                        </div>
                        <div className={classes.termsAndCondAndFacebookBox}>
                            <div className={classes.termsAndCondBox}>
                                <span onClick={() => onClickOpenTerms()}>{t('ft-link1')}</span>
                                     |
                                <span onClick={() => onClickOpenPrivacyPolicy()}>{t('ft-link2')}</span>
                                        |
                                <span onClick={() => onClickOpenFaq()}>{t('ft-link3')}</span>
                            </div>
                            <div className={classes.facebookBox}>
                                <span>{t('ft-social-label')}</span>
                                <Link underline="none" color="inherit" href={"https://www.facebook.com/digitalmnu"} target="_blank">
                                    <img src={ICONS.facebook}></img>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>

            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={openDialog}>
                <DialogContent>
                    <Popup
                        type={status}
                        close={closeHandler}
                        login={login}
                    />
                </DialogContent>
            </Dialog>

            { faqsRender}
            { loader}
            { termsAndCond}
            { privacyPolicy}
        </>
    )
}

Register.propTypes = {
    classes: PropTypes.object
}

export default (
    withStyles(styles)
)(Register)


