export default theme => ({
    root: {
        maxWidth: 'none',
        padding: '0px 0px 0px',
        color: 'white',
        backgroundColor: '#000000',
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingBottom: '0px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0px 0px 0px',
        },
        '& .MuiGrid-root': {
            width: '100%',
            flexGrow: 1,
            flexWrap: 'noWrap'
        }
    },
    header: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',

        position: 'fixed',
        top: '0',
        zIndex: '1000',

        flexGrow: '0 !important',
        justifyContent: 'flex-end',
        backgroundColor: '#191919',
        minHeight: '70px',
        // '& p': {
        //     display: 'flex',
        //     // justifyContent: 'center',
        //     // alignItems: 'center',
        //     transition:' all 0.1s linear',

        //     fontSize: '14px',
        //     lineHeight: '19px',
        //     color: '#FFFFFF',
        //     letterSpacing: '0.47px',
        //     // margin: '0 13px',
        //     cursor: 'pointer',
        //     // '&:hover': {
        //     //     color: 'rgba(170, 27, 37, 1)',
        //     //     // fontWeight: 'bold'
        //     //     fontWeight: 'bold',
        //     //     position: 'absolute'
        //     // }
        // },
        // '& a': {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     transition:' all 0.1s linear',


        //     fontSize: '14px',
        //     lineHeight: '19px',
        //     color: '#FFFFFF',
        //     letterSpacing: '0.47px',
        //     margin: '0 0px',
        //     cursor: 'pointer',
        //     '&:hover': {
        //         color: 'rgba(170, 27, 37, 1)',
        //         fontWeight: 'bold'
        //     }
        // }
    },
    headerContent: {
        // backgroundColor: "red",
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '16px 0',
        [theme.breakpoints.between(600, 1300)]: {
            padding: '16px 30px',
        },
        [theme.breakpoints.down(599)]: {
            padding: '16px 20px',
        },
    },
    boxLogo: {
        flex: '1',
        maxHeight: '34px',
        minHeight: '34px',
        cursor: 'pointer'
    },
    optionsHeader: {
        flex: '5',
        position: 'relative',
        // backgroundColor: "brown",
        display: 'flex',
        alignItems: 'center',
        ['@media (max-width:1000px)']: {
            display: 'none'
        },
        // '& p': {
        //     // margin: 'auto 12px'
        // },
        // '& a': {
        //     margin: 'auto 12px'
        // }
    },
    actionsHeader: {
        ['@media (max-width:1000px)']: {
            display: 'none'
        },
        // backgroundColor: "orange",
        display: 'flex',
        alignItems: 'center',
    },
    actionsHeaderLng: {
        display: 'flex',
        alignItems: 'center',
    },
    actionsMobileHeader: {
        ['@media (max-width:1000px)']: {
            width: '40px',
            // backgroundColor: "brown",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        },
        display: 'none'
    },
    gridContent: {
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    paperForm: {
        color: 'inherit',
        backgroundColor: 'transparent',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 20px',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center'
        },
    },
    paperImage: {
        height: '100%',
        backgroundColor: 'transparent',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        '& img': {
            position: 'absolute',
            maxHeight: '100%',
            minWidth: '397px',
            [theme.breakpoints.down('lg')]: {
                minWidth: '273px',
            },
            '&:first-child': {
                zIndex: 1
            },
            '&:nth-child(2)': {
                marginLeft: '252px',
                [theme.breakpoints.down('lg')]: {
                    marginLeft: '172px',
                },
            },
        },
    },
    footer: {
        backgroundColor: '#191919',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '11px',
        lineHeight: '13px',
        marginTop: '30px',
        minHeight: '70px',
        '& .MuiLink-root': {
            marginLeft: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px',
        },
    },
    footerContent: {
        maxWidth: '1280px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '6px 0',
        flexWrap: 'wrap',
        [theme.breakpoints.between(600, 1300)]: {
            padding: '12px 30px',
        },
        [theme.breakpoints.down(599)]: {
            padding: '12px 20px',
        },
    },
    termsAndCondAndFacebookBox: {
        display: 'flex',
        position: 'relative',
        flex: 1
    },
    assigneCycBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& a': {
            marginTop: '5px',
            marginRight: '5px'
        }
    },
    termsAndCondBox: {
        padding: '13px 0px',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        fontSize: '11px',
        letterSpacing: '0px',
        color: '#6E6E6E',
        marginLeft: 'auto',
        '& span:first-child': {
            marginRight: '10px',
            cursor: 'pointer',
            '&:hover': {
                color: 'rgba(170, 27, 37, 1)'
            }
        },
        '& span:nth-child(2)': {
            marginLeft: '10px',
            marginRight: '10px',
            cursor: 'pointer',
            '&:hover': {
                color: 'rgba(170, 27, 37, 1)'
            }
        },
        '& span:nth-child(3)': {
            marginLeft: '10px',
            cursor: 'pointer',
            '&:hover': {
                color: 'rgba(170, 27, 37, 1)'
            }
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0
        },
    },
    facebookBox: {
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        fontSize: '11px',
        letterSpacing: '0px',
        color: '#6E6E6E',
        marginLeft: 'auto',
        ['@media (max-width:545px)']: {
            '& img': {
                width: '25px !important',
                heigth: '25px !important'
            },
            position: 'absolute',
            right: '10px',
            transform: 'translate(0px, -30px)'
        },
        [theme.breakpoints.down('md')]: {
            '& img': {
                width: '30px',
                heigth: '30px'
            },
            '& span': {
                display: 'none'
            }
        },
        '& a': {
            marginTop: '2px'
        }
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingTop: '50px',

    },
    containerScrolled: {
        backgroundColor: 'brown',
        overflow: 'auto',
        ['@media (min-height:667px)']: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        }
    },
    gridContentItemLeft: {
        [theme.breakpoints.between(1279, 'lg')]: {
            paddingLeft: '140px'
        },
    },
    gridContentItemRight: {
        backgroundColor: 'orange',
        [theme.breakpoints.between(1279, 'lg')]: {
            paddingLeft: '70px'
        }
    }
})