export default theme => ({
    root: {
        maxWidth: '407px'
    },
    boxLoading: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 29px',
        alignItems: 'center',
        textAlign: 'center',
        '& .title': {
            fontSize: '21px',
            lineHeight: '25px',
            letterSpacing: '0.71px',
            textTransform: 'uppercase',
            margin: '26px 0',
            fontWeight: 'bold'
        },
        '& .info': {
            fontSize: '16px',
            lineHeight: '29px',
            letterSpacing: '0.54px'
        }
    },
    boxSuccess: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 29px',
        alignItems: 'center',
        textAlign: 'center',
        '& .title': {
            fontSize: '21px',
            lineHeight: '25px',
            letterSpacing: '0.71px',
            textTransform: 'uppercase',
            margin: '26px 0',
            fontWeight: 'bold'
        },
        '& .info': {
            fontSize: '16px',
            lineHeight: '29px',
            letterSpacing: '0.54px',
            '& span': {
                fontWeight: 'bold',
                color: '#8C161E',
                cursor: 'pointer'
            }
        }
    },
    boxError: {
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 29px',
        alignItems: 'center',
        textAlign: 'center',
        '& .title': {
            fontSize: '21px',
            lineHeight: '25px',
            letterSpacing: '0.71px',
            textTransform: 'uppercase',
            margin: '26px 0',
            fontWeight: 'bold'
        },
        '& .info': {
            fontSize: '16px',
            lineHeight: '29px',
            letterSpacing: '0.54px',
            maxWidth: '210px',
            '& span': {
                fontWeight: 'bold',
                color: '#8C161E',
                cursor: 'pointer'
            }
        }
    },
    personIcon: {
        fontSize: '62px',
        color: '#F5C602'
    },
    checkIcon: {
        fontSize: '62px',
        color: '#46B700'
    },
    errorIcon: {
        fontSize: '62px',
        color: '#C40303'
    },
    buttonClose: {
        color: '#8C161E',
        fontSize: '16px',
        lineHeight: '29px',
        letterSpacing: '0.54px',
        cursor: 'pointer',
        marginTop: '28px'

    }
})