import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const languages = ['en', 'pt', 'es', 'fr'];

const loadLanguage = () => {
    const lang = localStorage.getItem("lang");
    if(Boolean(lang)){
      return lang;
    } else {
      localStorage.setItem("lang","pt");
      return 'pt' 
    }
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: loadLanguage(),
    fallbackLng: 'en',
    
    debug: false,
    whitelist: languages,

    interpolation: {
      escapeValue: false,
    }
  })

export default i18n;