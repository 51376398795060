export default theme => ({
    root: {},
    dialogTitle: {
        padding: '30px',
        [theme.breakpoints.down('sm')]: {
            padding: '15px 30px',
        },
        '& h2': {
            color: '#212121',
            fontSize: '21px',
            lineHeight: '28px',
            fontWeight: 'bold'
        }
    },
    dialogActionsRoot: {
        borderTop: '1px solid #E5E5E5',
        display: 'flex',
        justifyContent: 'center',
        padding: '23px 0',
        margin: '20px 0 0',
        '& p:first-child': {
            color: '#8C161E',
            fontSize: '160x',
            lineHeigh: '29px',
            cursor: 'pointer'
        }
    }

})