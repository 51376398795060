import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

/* Material-UI */
import {
    withStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Paper,
    Tabs,
    Tab,
    Box
} from '@material-ui/core'
import styles from './styles';

import {
    TERMS_RESTAURANTES,
    TERMS_CLIENTE_FINAL
} from './../../data/TermsAndConditions'



const TermsAndConditions = ({
    classes,
    closeTerms
}) => {


    const [value, setValue] = React.useState(0);
    const { t, i18n } = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onClickCloseHander = () => {
        closeTerms()
    }

    
  return (
      <Dialog
              fullWidth={true}
              maxWidth = {"md"}
              disableBackdropClick={true}
              disableEscapeKeyDown={true}
              open={true}>
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {t('tc-title')}
          </DialogTitle>
          <CustomTabs
              className={classes.tabs}
              variant="fullWidth"
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
          >
              <Tab label={t('tc-tab1')} {...a11yProps(0)} className={classes.tab} />
              <Tab label={t('tc-tab2')} {...a11yProps(1)} className={classes.tab} />
          </CustomTabs>
          <DialogContent className={classes.dialogContent}>
              <Paper square elevation={0}>
             
                  <TabPanel value={value} index={0}>
                      <div dangerouslySetInnerHTML={{ __html: t('tc-description1') }} />
                   </TabPanel>
                  <TabPanel value={value} index={1}>
                      <div dangerouslySetInnerHTML={{ __html: t('tc-description2') }} />
                  </TabPanel>
              </Paper>
          </DialogContent>
          <DialogActions className={classes.dialogActionsRoot}>
              <p onClick={() => onClickCloseHander()}>
                {t('tc-action-btn1')}
              </p>
          </DialogActions>
          </Dialog>  
  )
}

TermsAndConditions.propTypes = {
    classes: PropTypes.object,
    closeTerms: PropTypes.func
}

export default (
    withStyles(styles)
)(TermsAndConditions)


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const CustomTabs = withStyles({
    root: {
        borderBottom: '1px solid #E5E5E5',
        margin: '0px 30px 30px'
    },
    indicator: {
        borderBottom: '2px solid #8C161E'
    }
})(Tabs);


