import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

/* Material */
import {
    withStyles
} from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const Popup = ({
    classes,
    type,
    close,
    login
}) => {

    const { t, i18n } = useTranslation();

    const loadingRender =
        <div className={classes.boxLoading}>
            <PersonIcon className={classes.personIcon} />
            <p className="title">{t('pop-ca-title')}</p>
            <p className="info">{t('pop-ca-info1')}</p>
        </div>


    const successRender =
        <div className={classes.boxSuccess}>
            <CheckCircleRoundedIcon className={classes.checkIcon} />
            <p className="title">{t('pop-sac-title')}</p>
            <p className="info">{t('pop-sac-info1')}</p>
            <p className="info">{t('pop-sac-info2')}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                {t('pop-sac-action-btn')}
            </p>
        </div>

    const errorRender =
        <div className={classes.boxError}>
            <ErrorIcon className={classes.errorIcon} />
            <p className="title">{t('pop-eca-title')}</p>
            <p className="info">{t('pop-eca-info1')}</p>
            <p className="info">{t('pop-eca-info2')}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                {t('pop-eca-action-btn')}
            </p>
        </div>

    const errorEmailAlreadyExistRender =
        <div className={classes.boxError}>
            <ErrorIcon className={classes.errorIcon} />
            <p className="title">{t('pop-eea-title')}</p>
            <p className="info">{t('pop-eea-info1-pt1')}
                <span onClick={() => login()}>{t('pop-eea-info1-pt2')}</span>.
            </p>
            <p className="info">{t('pop-eea-info2')}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                {t('pop-eea-action-btn')}
            </p>
        </div>

    const successEmailValidateSuccessRender =
        <div className={classes.boxSuccess}>
            <CheckCircleRoundedIcon className={classes.checkIcon} />
            <p className="title">{t('pop-sve-title')}</p>
            <p className="info">{t('pop-sve-info1')}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                {t('pop-sve-action-btn')}
            </p>
        </div>

    const successEmailValidateErrorRender =
        <div className={classes.boxError}>
            <ErrorIcon className={classes.errorIcon} />
            <p className="title">{t('pop-eea-title')}</p>
            <p className="info">{t('pop-eea-info1-pt1')}{t('pop-eea-info1-pt2')}</p>
            <p className="info">{t('pop-eea-info2')}</p>
            <p className={classes.buttonClose} onClick={() => close()}>
                {t('pop-eea-action-btn')}
            </p>
        </div>


    let render = null;

    switch (type) {
        case 'LOADING':
            render = loadingRender
            break;
        case 'SUCCCESS':
            render = successRender
            break;
        case 'ERROR':
            render = errorRender
            break;
        case 'EMAIL_ALREADY_EXIST':
            render = errorEmailAlreadyExistRender
            break;
        case 'EMAIL_CONFIRM_SUCCCESS':
            render = successEmailValidateSuccessRender
            break
        case 'EMAIL_CONFIRM_ERROR':
            render = successEmailValidateErrorRender
            break
        default:
            break;
    }

    return (
        <div className={classes.root}>
            {render}
        </div>
    )
}

Popup.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    close: PropTypes.func,
    login: PropTypes.func
}

export default compose(
    withStyles(styles)
)(Popup)


