import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { compose } from 'recompose';


import {
    withStyles,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography

} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const PanelExpansion = ({
    classes,
    title,
    info
}) => {
  return (
    <>
          <ExpansionPanel className={classes.expansionPanel}>
              <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                  <Typography className={classes.heading}>{title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                  <div dangerouslySetInnerHTML={{ __html: info }} />
              </ExpansionPanelDetails>
          </ExpansionPanel>
    </>
  )
}

PanelExpansion.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.object.isRequired,
    info: PropTypes.object.isRequired
}

export default compose (
    withStyles()
)(PanelExpansion)
