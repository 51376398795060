import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Register from './../screens/Register';

const Routes = () => {
    return (
        <Switch>
            <Route
                path="/registration"
                exact
                component={Register}
            />
            <Redirect
                exact
                from="/"
                to="/registration"
            />
            <Redirect
                to="/"
            />
        </Switch>
    )
}

export default Routes;
