import React, { useState } from 'react'
import PropTypes from 'prop-types';
import styles from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    COLOR_PRIMARY
} from '../../theme/colors';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
    withStyles,
    TextField,
    FormControl,
    Button,
    Checkbox
} from '@material-ui/core'

import MaskedInput from 'react-text-mask';


import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TermsAndConditions from '../../components/TermsAndConditions';
import Policy from '../../components/Policy';




const FormStep2 = ({
    classes,
    data,
    back,
    createAccount
}) => {

    const [acceptTerms, setAcceptTerms] = useState(false)
    const { t, i18n } = useTranslation();
    const [openTerms, setOpenTerms] = useState(false)
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)


    const formik = useFormik({
        validateOnMount: true,
        initialValues: {
            phone: data.phone,
            address: data.address,
            zipCode: data.zipCode,
            city: data.city
        },
        validationSchema: Yup.object().shape({
            phone: Yup.string()
                .required('Campo obrigatório'),
            address: Yup.string()
                .required('Campo obrigatório'),
            zipCode: Yup.string()
                .required('Campo obrigatório'),
            city: Yup.string()
                .required('Campo obrigatório')
        }),
        onSubmit: async (values) => {
            createAccount(values);
        },

    })

    const handleChangeCheckTerms = (event) => {
        setAcceptTerms(event.target.checked);
    };

    const onClickOpenTerms = () => {
        setOpenTerms(true)
    }

    const onClickOpenPrivacyPolicy = () => {
        setOpenPrivacyPolicy(true)
    }

    const termsAndCond = openTerms ? <TermsAndConditions closeTerms={() => setOpenTerms(false)} /> : null
    const privacyPolicy = openPrivacyPolicy ? <Policy closePolicies={() => setOpenPrivacyPolicy(false)} /> : null

    return (
        <>
            <form onSubmit={formik.handleSubmit} >

                <div className={classes.boxBack} onClick={() => {
                    const data = formik.values
                    back(data)
                }
                }>
                    <NavigateBeforeIcon style={{ color: COLOR_PRIMARY }} />
                    <p>{t('s0-step2-link1')}</p>
                </div>
                <div className={classes.boxInputs}>
                    <FormControl
                        fullWidth
                        margin='normal'>
                        <TextField
                            name="phone"
                            type="text"
                            label={t('s0-step2-label1')}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.phone && formik.touched.phone}
                            helperText={(formik.errors.phone && formik.touched.phone) && formik.errors.phone}
                        />

                        {/*<Input
                            autoComplete={false}
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            name="phone"
                            id="formatted-text-mask-input"
                            inputComponent={TextMaskCustom}
                        />
                        */}
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin='normal'>
                        <TextField
                            name="address"
                            type="text"
                            label={t('s0-step2-label2')}
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.address && formik.touched.address}
                            helperText={(formik.errors.address && formik.touched.address) && formik.errors.address}
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin='normal'>
                        <TextField
                            name="zipCode"
                            type="text"
                            label={t('s0-step2-label3')}
                            value={formik.values.zipCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.zipCode && formik.touched.zipCode}
                            helperText={(formik.errors.zipCode && formik.touched.zipCode) && formik.errors.zipCode}
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin='normal'>
                        <TextField
                            name="city"
                            type="text"
                            label={t('s0-step2-label4')}
                            value={formik.values.city}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.city && formik.touched.city}
                            helperText={(formik.errors.city && formik.touched.city) && formik.errors.city}
                        />
                    </FormControl>
                    <div item className={classes.gridActionsButton}>
                        <div className={classes.boxTerms}>
                            <Checkbox
                                checked={acceptTerms}
                                onChange={handleChangeCheckTerms}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <p>
                                {t('s0-step2-label5')}&nbsp;
                                <span onClick={() => onClickOpenTerms()}>{t('s0-step2-link2')}</span>
                                	&nbsp;{t('s0-step2-label6')}	&nbsp;
                                <span onClick={() => onClickOpenPrivacyPolicy()}>{t('s0-step2-link3')}</span>
                            .</p>
                        </div>
                        <Button type="submit" disabled={!acceptTerms || !formik.isValid}>{t('s0-step2-action-btn1')}</Button>
                    </div>
                </div>
            </form>
            {termsAndCond}
            {privacyPolicy}
        </>
    )
}

FormStep2.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    createAccount: PropTypes.func,
    back: PropTypes.func,
}

export default (
    withStyles(styles)
)(FormStep2)

function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/,]}
            placeholderChar={'0'}
            showMask
        />
    );
}
TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};