import React from 'react'
import PropTypes from 'prop-types';
import styles from './styles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
    withStyles,
    Typography,
    TextField,
    FormControl,
    Button,
    Link
} from '@material-ui/core'



const FormStep1 = ({
    classes,
    data,
    nextStep
}) => {

    const { t, i18n } = useTranslation();

    const formik = useFormik({
        validateOnMount: true,
        initialValues: {
            establishmentName: data.establishmentName,
            email: data.email,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('invalid email')
                .required('Campo obrigatório'),
            establishmentName: Yup.string()
                .required('Campo obrigatório')
        }),
        onSubmit: async (values) => {
            nextStep(values);
        }})

    
  return (
      <form onSubmit={formik.handleSubmit} >
         { /* 
         <div className={classes.boxLogo}>
              <img src={logotipo} />
          </div>
        */ }
          <div className={classes.boxTitle}>{t('s0-step1-title')}</div>
          <div className={classes.boxInputs}>
                <FormControl fullWidth margin='normal'>
                    <TextField
                        name="establishmentName"
                        type="text"
                        label={t('s0-step1-label1')}
                        value={formik.values.establishmentName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.establishmentName && formik.touched.establishmentName}
                        helperText={(formik.errors.establishmentName && formik.touched.establishmentName) && formik.errors.establishmentName}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    margin='normal'>
                    <TextField
                        name="email"
                        type="email"
                        label={t('s0-step1-label2')}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email && formik.touched.email}
                        helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                    />
                </FormControl>
              <div item className={classes.gridActionsButton}>
                  <Button type="submit"
                      disabled={!formik.isValid}>
                      {t('s0-step1-action-btn1')}
                  </Button>
                  <div className={classes.info}>
                      <p>{t('s0-step1-label3')}</p>
                      <Link underline="none" color="inherit" href={"https://cy-cms.pt/"} target="_blank">
                          {t('s0-step1-link1')}
                      </Link>
                  </div>
              </div>
          </div>
      </form>
  )
}

FormStep1.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    nextStep: PropTypes.func
}

export default (
    withStyles(styles)
)(FormStep1)


