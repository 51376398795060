import React from 'react';
import './assets/styles/index.css';
import Routes from './routes/routes';

/* Material-UI */
import { ThemeProvider } from "@material-ui/core/styles";

import theme from './theme';


const App = () => {
  return (

    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  )
}

App.propTypes = {}

export default App
