import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
/* Material-UI */
import {
    withStyles,
    Dialog,
    DialogContent,
    DialogActions,
    Paper,
    Box,
    DialogTitle
} from '@material-ui/core'
import styles from './styles';

import {
    TERMS_POLITICA_PRIVACIDADE
} from '../../data/TermsAndConditions'



const TermsAndConditions = ({
    classes,
    closePolicies
}) => {

    const { t, i18n } = useTranslation();

    const onClickCloseHander = () => {
        closePolicies()
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={true}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                {t('pp-title')}
            </DialogTitle>
            <DialogContent>
                <Paper square elevation={0}>
                    <div dangerouslySetInnerHTML={{ __html: t('pp-description') }} />
                </Paper>
            </DialogContent>
            <DialogActions className={classes.dialogActionsRoot}>
                <p onClick={() => onClickCloseHander()}>
                    {t('pp-action-btn1')}
                </p>
            </DialogActions>
        </Dialog>
    )
}

TermsAndConditions.propTypes = {
    classes: PropTypes.object,
    closeTerms: PropTypes.func
}

export default (
    withStyles(styles)
)(TermsAndConditions)


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


