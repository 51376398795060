import React from 'react'
import PropTypes from 'prop-types';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

/* Material-UI */
import {
    withStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Paper,
    Tabs,
    Tab,
    Box
} from '@material-ui/core'

import {
    FAQS_RESTAURANTE,
    FAQS_CLIENTES_FINAIS
} from '../../data/faqs'

import PanelExpansion from '../PanelExpansion'



const Faqs = ({
    classes,
    closeFaqs
}) => {


    const [value, setValue] = React.useState(0);
    const { t, i18n } = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onClickCloseHander = () => {
        closeFaqs()
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={true}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                {t('faq-title')}
            </DialogTitle>
            <CustomTabs
                className={classes.tabs}
                variant="fullWidth"
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                <Tab label={t('faq-tab1')} {...a11yProps(0)} className={classes.tab} />
                <Tab label={t('faq-tab2')} {...a11yProps(1)} className={classes.tab} />
            </CustomTabs>
            <DialogContent className={classes.dialogContent}>
                <Paper square elevation={0}>

                    <TabPanel value={value} index={0}>
                        <PanelExpansion key={1} title={t('faq-tab1-topic1-key')} info={t('faq-tab1-topic1-value')} />
                        <PanelExpansion key={2} title={t('faq-tab1-topic2-key')} info={t('faq-tab1-topic2-value')} />
                        <PanelExpansion key={3} title={t('faq-tab1-topic3-key')} info={t('faq-tab1-topic3-value')} />
                        <PanelExpansion key={4} title={t('faq-tab1-topic4-key')} info={t('faq-tab1-topic4-value')} />
                        <PanelExpansion key={5} title={t('faq-tab1-topic5-key')} info={t('faq-tab1-topic5-value')} />
                        <PanelExpansion key={6} title={t('faq-tab1-topic6-key')} info={t('faq-tab1-topic6-value')} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <PanelExpansion key={1} title={t('faq-tab2-topic1-key')} info={t('faq-tab2-topic1-value')} />
                        <PanelExpansion key={2} title={t('faq-tab2-topic2-key')} info={t('faq-tab2-topic2-value')} />
                        <PanelExpansion key={3} title={t('faq-tab2-topic3-key')} info={t('faq-tab2-topic3-value')} />
                        <PanelExpansion key={4} title={t('faq-tab2-topic4-key')} info={t('faq-tab2-topic4-value')} />
                        <PanelExpansion key={5} title={t('faq-tab2-topic5-key')} info={t('faq-tab2-topic5-value')} />
                        <PanelExpansion key={6} title={t('faq-tab2-topic6-key')} info={t('faq-tab2-topic6-value')} />
                        <PanelExpansion key={6} title={t('faq-tab2-topic7-key')} info={t('faq-tab2-topic7-value')} />
                    </TabPanel>

                </Paper>
            </DialogContent>
            <DialogActions className={classes.dialogActionsRoot}>
                <p onClick={() => onClickCloseHander()}>
                    {t('faq-action-btn1')}
              </p>
            </DialogActions>
        </Dialog>
    )
}

Faqs.propTypes = {
    classes: PropTypes.object,
    closeFaqs: PropTypes.func
}

export default (
    withStyles(styles)
)(Faqs)


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const CustomTabs = withStyles({
    root: {
        borderBottom: '1px solid #E5E5E5',
        marginBottom: '30px'
    },
    indicator: {
        borderBottom: '2px solid #8C161E'
    }
})(Tabs);


