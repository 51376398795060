import {
    COLOR_PRIMARY,
    COLOR_PRIMNARY_HOVER
} from './../../theme/colors';

export default theme => ({
    root: {},
    boxTitle: {
        maxWidth: '450px',
        maxHeight: 'auto',
        margin: '0px 0 20px',
        fontSize: '50px',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        letterSpacing: '0px',
        lineHeight: '57px',
        ['@media (max-width:768px)']: {
            width: '260px',
            marginTop: '0px',
            fontSize: '30px',
            lineHeight: '38px',
        },
    },
    boxInputs: {
        maxWidth: '540px'
    }, 
    gridActionsButton: {
        marginTop: '38px',
        display: 'flex',
        flexDirection: 'column',
        '& button': {
            width: 'max-content'
        }
    },
    info: {
        display: 'inline-flex',
        marginTop: '30px',
        '& p': {
            fontSize: '13px',
            lineHeight: '24px',
            marginRight: '5px'
        },
        '& a': {
            color: COLOR_PRIMARY,
            cursor: 'pointer',
            fontSize: '13px',
            lineHeight: '24px',
             fontWeight: 'bold',
            '&:hover': {
                color: COLOR_PRIMNARY_HOVER
            }
        }
    },
     fullWidth: {
        width: '100%'
    },
})


